module.exports = {
  pathPrefix: '/mrugaadhvaryu',
  siteUrl: 'http://kadhvary.gitlab.io',
  siteTitle: 'Mruga Adhvaryu',
  siteDescription: 'Senior Data Scientist | Researcher | Author',
  author: 'Kushal Adhvaryu',
  postsForArchivePage: 3,
  defaultLanguage: 'en',
  disqusScript: process.env.DISQUS_SCRIPT || '',
  pages: {
    home: '/',
    blog: 'blog',
    contact: 'contact',
    resume: 'resume',
    tag: 'tags',
    yay: 'yay',
  },
  social: {
    linkedin: 'https://www.linkedin.com/in/mrugaadhvaryu/',
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT,
  googleAnalyticTrackingId: process.env.GA_TRACKING_ID || '',
  tags: {
    sql: {
      name: 'sql',
      description: 'SQL is a standard programming language specifically designed for storing, retrieving, managing or manipulating the data inside database.',
      color: '#f0da50',
    },
    spss: {
      name: 'spss',
      description: 'SPSS Statistics is a software package used for interactive, or batched, statistical analysis. ',
      color: '#90c53f',
    },
    r: {
      name: 'r',
      description: 'R is a language and environment for statistical computing and graphics. ... R provides a wide variety of statistical (linear and nonlinear modelling, classical statistical tests, time-series analysis, classification, clustering, …) and graphical techniques, and is highly extensible.',
      color: '#eb428e',
    },
    machinelearning: {
      name: 'machinelearning',
      description: 'Machine learning is the study of computer algorithms that improve automatically through experience. It is seen as a subset of artificial intelligence.',
      color: '#257acc',
    },
    deeplearning: {
      name: 'deeplearning',
      description: 'Deep learning is an artificial intelligence (AI) function that imitates the workings of the human brain in processing data and creating patterns for use in decision making. ... Also known as deep neural learning or deep neural network.',
      color: '#61dbfa',
    },
    hadoop: {
      name: 'hadoop',
      description: 'Hadoop is an open-source software framework for storing data and running applications on clusters of commodity hardware. It provides massive storage for any kind of data, enormous processing power and the ability to handle virtually limitless concurrent tasks or jobs.',
      color: '#6f309f',
    },
    datavisualization: {
      name: 'datavisualization',
      description: 'Data visualization is the graphical representation of information and data. By using visual elements like charts, graphs, and maps, data visualization tools provide an accessible way to see and understand trends, outliers, and patterns in data.',
      color: '#dd3431',
    },
    bigdata: {
      name: 'bigdata',
      description: 'Big data refers to the large, diverse sets of information that grow at ever-increasing rates. It encompasses the volume of information, the velocity or speed at which it is created and collected, and the variety or scope of the data points being covered.',
      color: '#43ace0',
    },
    teamwork: {
      name: 'teamwork',
      description: 'Work done by several colleagues with each doing a part but all subordinating personal prominence to the efficiency of the whole',
      color: '#f9c646',
    },
    python: {
      name: 'python',
      description: 'Data Science Python is widely used and is a favorite tool along being a flexible and open sourced language. Its massive libraries are used for data manipulation and are very easy to learn even for a beginner data analyst.',
      color: '#f9c646',
    },
  },
};
